export default () => {
    const buttons = document.querySelectorAll('button.download-print-page');

    if (!buttons.length) {
        return;
    }

    function print() {
        window.print();
    }

    buttons.forEach((button) => {
        button.addEventListener('click', () => {
            console.log('print');
            print();
        })
    })
};
