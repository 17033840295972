export default () => {
    const accordions = document.querySelectorAll('.accordion');

    function closeAccordions() {
        accordions.forEach((el) => {
            el.classList.remove('accordion-is-open');
        })
    }

    if (accordions.length) {
        accordions.forEach((acc) => {
            const header = acc.querySelector('.accordion-header');


            header.addEventListener('click', () => {
                if (acc.classList.contains('accordion-is-open')) {
                    acc.classList.remove('accordion-is-open');
                } else {
                    closeAccordions();
                    acc.classList.toggle('accordion-is-open');
                }
            })
        })
    }
}
