import Swiper, { EffectFade, Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default () => {
    const swipers = document.querySelectorAll('.swiper');
    const dateSwipers = document.querySelectorAll('.swiper-dates');
    const config = {
        carousel:  {
            modules: [Navigation, Pagination],
            effect: 'slide',
            slidesPerView: 1,
            spaceBetween: 16,
            grabCursor: true,
            loop: true,
            autoplay: {
                delay: 8000,
                disableOnInteraction: true,
            },
            navigation: {
                nextEl: '.swiper-container-news .swiper-button-next',
                prevEl: '.swiper-container-news .swiper-button-prev',
            },
            pagination: {
                el: '.swiper-news .swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                1268: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                },
            },
        },

        single: {
            modules: [Navigation],
            effect: 'slide',
            slidesPerView: 1,
            spaceBetween: 30,
            grabCursor: true,
            autoplay: {
                delay: 8000,
            },
            navigation: {
                nextEl: '.swiper-container-single .swiper-button-next',
                prevEl: '.swiper-container-single .swiper-button-prev',
            },
        },

        history: {
            modules: [Navigation, Pagination, EffectFade],
            effect: 'fade',
            slidesPerView: 1,
            spaceBetween: 30,
            allowTouchMove: false,
            navigation: {
                nextEl: '.swiper-container-history .swiper-button-next',
                prevEl: '.swiper-container-history .swiper-button-prev',
            },
            pagination: {
                el: '.swiper-container-history .swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            thumbs: {
                swiper: 'swiper-dates'
            }
        },

        dates: {
            modules: [Navigation, Pagination],
            effect: 'slide',
            slidesPerView: 3,
            centeredSlides: 'true',
            spaceBetween: 24,
            allowTouchMove: false,
            navigation: {
                nextEl: '.swiper-container-history .swiper-button-next',
                prevEl: '.swiper-container-history .swiper-button-prev',
            },
            pagination: {
                el: '.swiper-container-history .swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                },
                1268: {
                    slidesPerView: 5,
                    spaceBetween: 32,
                },
                1400: {
                    slidesPerView: 7,
                    spaceBetween: 32,
                },
            },
        }
    }

    swipers.forEach((element) => {
        let swiperType = element.dataset.swiperType;
        let swiper = new Swiper(element, config[swiperType]);
    });

    dateSwipers.forEach((e) => {
        const swiper = new Swiper(e, config['dates']);

        swiper.on('slideChange', function () {
            swiper.slides.forEach((slide) => {
                slide.classList.remove('slide-after-1');
                slide.classList.remove('slide-after-2');
                slide.classList.remove('slide-after-3');
                slide.classList.remove('slide-after-4');
            })

            let i = swiper.activeIndex;
            let slides = swiper.slides.slice(0, i).reverse();

            if(slides[0]) {
                slides[0].classList.add("slide-after-1");
            }

            if(slides[1]) {
                slides[1].classList.add("slide-after-2");
            }

            if(slides[2]) {
                slides[2].classList.add("slide-after-3");
            }

            if(slides[3]) {
                slides[3].classList.add("slide-after-4");
            }
        });
    })
}
