import CryptoJS from 'crypto-js';
import {defineComponent, computed, h} from 'vue';

export const Email = (props) => {
    const cryptoJsAesJson = {
        stringify: (cipherParams) => {
            const j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
        },
        parse: (jsonStr) => {
            const j = JSON.parse(jsonStr);
            const cipherParams = CryptoJS.lib.CipherParams.create({
                ciphertext: CryptoJS.enc.Base64.parse(j.ct)
            });
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
            return cipherParams;
        }
    };

    const decryptedMail = computed(() => {
        if (props.publicPassphrase === undefined || props.cryptMail === undefined) {
            return;
        }

        const decryptedString = CryptoJS.AES.decrypt(
            props.cryptMail,
            props.publicPassphrase,
            {
                format: cryptoJsAesJson,
            }
        ).toString(CryptoJS.enc.Utf8);

        return JSON.parse(decryptedString);
    });

    const href = computed(() => {
        return 'mailto:' + decryptedMail.value;
    });

    return h('a', {href: href.value}, [decryptedMail.value])
};

Email.props = {
    publicPassphrase: {
        type: String,
    },
    cryptMail: {
        type: String,
    },
}
