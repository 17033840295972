export default () => {
    const locations = document.querySelectorAll('.location-card');

    if (locations.length) {
        locations.forEach((loc) => {
            const label = document.querySelector(`#${loc.dataset.location}`)

            if (label) {
                loc.addEventListener('mouseover', () => {
                    label.classList.add('fill-green');
                })

                loc.addEventListener('mouseout', () => {
                    label.classList.remove('fill-green');
                })
            }
        })
    }
}
