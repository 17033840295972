export default () => {
	/* Define Cookie Functions */

	function setCookie(value, name, expires = 30) {
		const expireOffsetInMs = expires * 24 * 60 * 60 * 1000;
		const expiresAt = new Date();
		expiresAt.setTime(expiresAt.getTime() + expireOffsetInMs);
		document.cookie = name + "=" + value.toString() + ";expires=" + expiresAt.toUTCString() + ";path=/";
	}

	function getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);

		if (parts.length === 2) {
			return parts.pop().split(";").shift();
		}
	}

	function removeCookie(name) {
		document.cookie = name + "=; expires = Thu, 01 Jan 1970 00:00:00 GMT" + ";path=/";
	}

	function initialCookieCheck(name, bodyClass) {
		if (getCookie(name)) {
			document.documentElement.classList.add(bodyClass);
		} else {
			document.documentElement.classList.remove(bodyClass);
		}
	}

	/* Configure Navigation Toggles */

	const fontSizeCookieName = "font-size";
	const darkModeCookieName = "contrast";

    /* Check if cookie exists and toggles bodyclass if that's the case  */

    initialCookieCheck(fontSizeCookieName, "font-size-large");
	initialCookieCheck(darkModeCookieName, "contrast");

	document.querySelectorAll('[data-control="incfont"]').forEach((e) => {
		e.addEventListener("click", () => {
			if (!getCookie(fontSizeCookieName)) {
				setCookie(true, "font-size");
				document.documentElement.classList.add(`${fontSizeCookieName}-large`);
			}
		});
	});

	document.querySelectorAll('[data-control="decfont"]').forEach((e) => {
		e.addEventListener("click", () => {
			if (getCookie(fontSizeCookieName)) {
				removeCookie(fontSizeCookieName);
				document.documentElement.classList.remove(`${fontSizeCookieName}-large`);
			}
		});
	});

	document.querySelectorAll('[data-control="contrast"]').forEach((e) => {
		e.addEventListener("click", () => {
			if (getCookie(darkModeCookieName)) {
				removeCookie(darkModeCookieName);
				document.documentElement.classList.remove(darkModeCookieName);
			} else {
				setCookie(true, darkModeCookieName);
				document.documentElement.classList.add(darkModeCookieName);
			}
		});
	});
};
