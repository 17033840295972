export default () => {
    const embeds = document.querySelectorAll('.embed');
    embeds.forEach((embed) => {
        const placeholderImage = embed.querySelector('.embed-placeholder');

        placeholderImage.addEventListener('click', (event) => {
            const currentPlaceholder = event.target.parentNode;
            const currentEmbed = currentPlaceholder.closest('.embed-youtube');
            const iframe = currentEmbed.querySelector('iframe');

            if (!iframe) {
                return;
            }

            currentPlaceholder?.classList.add('hidden');

            const dataAttr = iframe.dataset.src;
            iframe.setAttribute('src', dataAttr);
        });
    });
}
