import { Loader } from '@googlemaps/js-api-loader';

let map = undefined;

function markPlace(results, status) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
        var marker = new google.maps.Marker({
            map: map,
            place: {
                placeId: results[0].place_id,
                location: results[0].geometry.location,
            },
        });
    }
}

export default (apiKey) => {
    const loader = new Loader({
        apiKey: apiKey,
        version: "weekly",
        libraries: ["places"]
    });

    loader.load().then(() => {
        const latLng = new google.maps.LatLng({lat:52.501251, lng: 13.368690});
        map = new google.maps.Map(document.getElementById("google-map"), {
            center: latLng,
            zoom: 17,
        });

        var request = {
            location: map.getCenter(),
            radius: '500',
            query: 'liquid reply'
        };

        var service = new google.maps.places.PlacesService(map);
        service.textSearch(request, markPlace);
    });
}

