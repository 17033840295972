export default () => {
    const copyToClipboards = document.querySelectorAll('.copy-to-clipboard')

    function copyLink(copyText) {
        navigator.clipboard.writeText(copyText);
    }

    copyToClipboards.forEach((copy) => {
        copy.addEventListener('click', () => {
            copyLink(copy.dataset.link);
        })
    })
};
