export default () => {
	// Define toggle Function

	function toggleItems(items) {
		items.forEach((item) => item.classList.toggle("open"));
	}

	// Toggle primary Nav

	const toggle = document.querySelector("#nav-toggle");
	const nav = document.querySelector("#nav-primary");

	if (!!toggle & !!nav) {
		toggle.addEventListener("click", () => {
			toggleItems([toggle, nav]);
		});
	}

	// Toggle Primary Nav Subitems

	const subitemToggles = document.querySelectorAll(".nav-toggle-sub");

	subitemToggles.forEach((item) => {
		const parent = item.closest(".has-subpages");

		if (!!parent) {
			item.addEventListener("click", () => {
				toggleItems([parent, item]);
			});
		}
	});

	// Toggle Search & Login

	const searchToggle = document.querySelector("#search-toggle");
	const loginToggle = document.querySelector("#login-toggle");
	const closeButtons = document.querySelectorAll('button[data-control="close-modal"]');

	function closeModals() {
		document.body.classList.remove("show-search", "show-login");
	}

	function scrollTop() {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	searchToggle.addEventListener("click", () => {
		scrollTop();
		closeModals();
		document.body.classList.add("show-search");
	});

	loginToggle.addEventListener("click", () => {
		scrollTop();
		closeModals();
		document.body.classList.add("show-login");
	});

	closeButtons.forEach((button) =>
		button.addEventListener("click", () => {
			closeModals();
		})
	);
};
