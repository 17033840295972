import {createApp} from 'vue'

import {Email} from './email';
import Accordion from './modules/accordion';
import CopyToClipboard from './modules/copyToClipboard';
import DownloadButton from './modules/downloadButton';
import GoogleMap from './modules/googleMap';
import Locations from './modules/locations';
import Modal from './modules/modal';
import Navigation from './modules/navigation';
import NavigationToggles from './modules/navigationToggles';
import Swiper from './modules/swiper';
import Tooltip from './modules/tooltip';
import YouTubeConsent from "./modules/youTubeConsent";

/**
 * Init custom modules
 */
Swiper();
Navigation();
NavigationToggles();
Modal();
Accordion();
YouTubeConsent();
Locations();
Tooltip();
CopyToClipboard();
DownloadButton();

window.GoogleMap = GoogleMap;


document.querySelectorAll('.email-component').forEach((el) => {
    createApp().component('email', Email).mount(el);
});

/*
 * Throw custom app.js loaded event
 */
const loadedEvent = new Event('app-js-loaded');
document.dispatchEvent(loadedEvent)
