import wait from 'waait';

export default () => {

	new class Modals {

		constructor() {
			this.init();
			this.addListenersOpen();
			this.addListenersClose();
			this.addListenerHash();
		}

		init() {
			const modal = document.querySelector(`[data-modal="${window.location.hash}"]`);

			if (window.location.hash && modal) {
				modal.classList.add("active");
				this.hideScroll();
			} else {
				document.querySelectorAll(`[data-modal]`).forEach((modal) => {
					modal.classList.remove("active");
				});
			}

            // Fixing iFrames causing 429 Request Error

            if (modal) {
                const iframes = modal.querySelectorAll('iframe');

                iframes.forEach((i) => {
                    i.src = i.getAttribute('link');
                })
            }
		}

		addListenersOpen() {
			document.querySelectorAll("a[data-modal-open]").forEach((trigger) => {
				trigger.addEventListener("click", this.openModal.bind(this));
			});
		}

		addListenersClose() {
			document.querySelectorAll("[data-modal-close]").forEach((trigger) => {
				trigger.addEventListener("click", this.closeModal.bind(this));
			});
		}

		addListenerHash() {
			window.addEventListener("hashchange", this.init.bind(this));
		}

		openModal(event) {
			event.preventDefault();

			const trigger = event.target.closest("[data-modal-open]");
			const modal = document.querySelector(`[data-modal="${trigger.hash}"]`);

			if (modal) {
				modal.classList.add("active");
				this.hideScroll();
			} else {
				console.error(`The modal window by id '${id}' not found!`);
			}

			window.location.hash = trigger.hash;
			sessionStorage.setItem("last-url", event?.oldURL);
		}

		closeModal(event) {
			event.preventDefault();

			const modal = event.target.closest("[data-modal]");

			if (modal) {
				modal.classList.remove("active");
				this.visibleScroll();

				const lastUrl = sessionStorage.getItem("last-url");

				if (lastUrl.indexOf("#") == -1) {
					history.pushState("", document.title, window.location.pathname + window.location.search);
				} else {
					const lastHash = lastUrl.substr(lastUrl.indexOf("#"));
					window.location.hash = lastHash;
				}

			} else {
				console.error(`The modal window by id '${id}' not found!`);
			}
		}

		hideScroll() {
			const scrollWidth = window.innerWidth - document.body.offsetWidth;

			if (scrollWidth) {
				document.querySelectorAll("[data-modal-fixed]").forEach((element) => {
					element.style.paddingRight = `${scrollWidth}px`;
				});

				document.body.style.paddingRight = `${scrollWidth}px`;
			}

			document.body.style.overflow = "hidden";
		}

		async visibleScroll() {
            await wait(300);

			document.querySelectorAll("[data-modal-fixed]").forEach((element) => {
				element.style.paddingRight = "0";
			});

			document.body.style.paddingRight = "0";
			document.body.style.overflow = "visible";
		}
	}();
};
