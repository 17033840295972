export default () => {
    const tooltips = document.querySelectorAll('.tooltip');
    const toggles = document.querySelectorAll('.tooltip-toggle');

    function closeAllTooltips() {
        tooltips.forEach((el) => {
            el.classList.remove('is-visible');
        })
    }

    if (toggles.length) {
        toggles.forEach((el) => {
            el.addEventListener('click', () => {
                closeAllTooltips();
                el.querySelector('.tooltip').classList.add('is-visible');
            })
        })
    }

    if (tooltips.length) {
        tooltips.forEach((tip) => {
            const close = tip.querySelector('.tooltip-close');

            close.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation(); // Prevents Event Bubbling

                close.parentNode.classList.remove('is-visible');
            })
        })
    }
}
